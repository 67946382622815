import { all, takeEvery, put, call} from "redux-saga/effects";
import handleError from "../utils/handleError";
import {
    createLoosePayments,
    generateLoosePaymentCode,
    getCoachesForEmail,
    listLoosePayments,
    loosePaymentsFilter
} from "../utils/webApi";
import {
    asyncGetLoosePayments,
    LoosePaymentActionTypes,
    setCoach,
    setCode,
    setIsLoading,
    setLoadingCoach,
    setLoadingCode,
    setLoadingSave,
    setLoosePayments,
    setTotal
} from "../reducers/loose-payment.reducer";
import { defaultPSize } from "../models/loose-payment.model";

function* requestLoosePayments({ payload }: any) {
    try {
        yield put(setIsLoading(true));

        const data = yield call(listLoosePayments, payload, defaultPSize);
        yield put(setLoosePayments(data.payments));
        yield put(setTotal(data.total));

    } catch (error) {
        handleError(error);
    } finally {
        yield put(setIsLoading(false));
    };
};

function* requestGetCoachByEmail({ payload }: any) {
    try {
        yield put(setLoadingCoach(true));

        const coach = yield call(getCoachesForEmail, payload);
        yield put(setCoach(coach));

    } catch (error) {
        handleError(error);
    } finally {
        yield put(setLoadingCoach(false));
    };
};

function* requestPostLoosePayment({ payload }: any) {
    try {
        yield put(setLoadingSave(true));
        payload.date_expiration = new Date(payload.date_expiration).toISOString();
        yield call(createLoosePayments, payload);

    } catch (error) {
        handleError(error);
    }finally {
        yield put(setLoadingSave(false));
        yield put(asyncGetLoosePayments(1));
    };
};

function* requestGetCode() {
    try {
        yield put(setLoadingCode(true));

        const code = yield call(generateLoosePaymentCode);
        yield put(setCode(code.id))
    } catch (error) {
        handleError(error);
    } finally {
        yield put(setLoadingCode(false));
    }
};

function* requestLoosePaymentsFilter({ payload }: any) {
    const { page, name, email } = payload;
    try {
        yield put(setIsLoading(true));

        const data = yield call(loosePaymentsFilter, page, defaultPSize, name, email);
        yield put(setLoosePayments(data.payments));
        yield put(setTotal(data.total));
        console.log('See filter result', data)

    } catch (error) {
        handleError(error);
    } finally {
        yield put(setIsLoading(false));
    };
};

export default function* MySaga(): any {
    yield all([
        yield takeEvery(LoosePaymentActionTypes.ASYNC_GET_LOOSE_PAYMENTS, requestLoosePayments),
        yield takeEvery(LoosePaymentActionTypes.ASYNC_GET_COACH_BY_EMAIL, requestGetCoachByEmail),
        yield takeEvery(LoosePaymentActionTypes.ASYNC_POST_LOOSE_PAYMENT, requestPostLoosePayment),
        yield takeEvery(LoosePaymentActionTypes.ASYNC_GET_CODE, requestGetCode),
        yield takeEvery(LoosePaymentActionTypes.ASYNC_GET_FILTER_PEYMENT, requestLoosePaymentsFilter),
    ]);
};
