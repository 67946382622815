import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Table, Input, Icon, Input as AntInput } from 'antd';
import TitleSection from '../../../components/title-section/TitleSection';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInternalLink, editInternalLink, openCheckout } from '../../../reducers/internal-links.reducer';
import { history } from '../../../config/Store.config';
import { IState } from '../../../reducers/reducers';
import Loading from '../../../components/loading/Loading';

import './LinkDetail.scss';
import { formatDateWithHours } from '../../../utils/moment.functions';
import { statusTranslations } from '../internal-links-list/InternalLinksList';
import { IAudit } from '../../../models/internal-links.model';

interface DetailState {
    id: string;
}

type LinkDetailProps = RouteComponentProps<DetailState>;

const InternalLinksDetail: React.FC<LinkDetailProps> = ({ match }) => {
    const id = match.params.id as string;

    const dispatch = useDispatch();
    const { link, loading } = useSelector(({ internalLinks }: IState) => ({
        link: internalLinks.internalLink,
        loading: internalLinks.isLoading,
    }));

    const [notes, setNotes] = useState('');
    const [status, setStatus] = useState('');

    const decreAudits = (audits: IAudit[]) => {
        const sortedByCreatedAt = audits.sort((a, b) => {
            return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
        });
        return sortedByCreatedAt;
    };

    useEffect(() => {
        if (link) {
            setStatus(link.status);
            setNotes(link.observation || '');
        }
    }, [link]);

    useEffect(() => {
        dispatch(fetchInternalLink(id));
    }, [id, dispatch]);

    const formatPrice = (value: number): string => {
        return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
    };

    const translateStatus = (status: string): string => {
        return statusTranslations[status as keyof typeof statusTranslations] || status;
    };

    const handleSubmit = (observation: string, newStatus?: string) => {
        if (newStatus) {
            dispatch(editInternalLink(id, observation, newStatus));
        } else {
            dispatch(editInternalLink(id, observation));
        }
    };

    const handleSaveObservation = () => {
        handleSubmit(notes);
    };

    const handleStatusChange = (newStatus: string) => {
        setStatus(newStatus);
        handleSubmit(notes, newStatus);
    };

    const renderActionButtons = () => {
        const buttons = [];

        switch (status) {
            case 'WAIT_PUBLISH':
                buttons.push(<Button key='2' type='primary' onClick={() => handleStatusChange('PUBLISHED')}>Publicar</Button>);
                buttons.push(<Button key='3' type='primary' onClick={() => handleStatusChange('WAITING_CORRECTION')}>Solicitar Correção</Button>);
                break;
        }

        return buttons;
    };

    const columns = [
        {
            title: 'Data e hora da criação',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt: string) => createdAt ? formatDateWithHours(createdAt) : ''
        },
        {
            title: 'Antigo Status',
            dataIndex: 'oldStatus',
            key: 'oldStatus',
            render: (oldStatus: string) => translateStatus(oldStatus) || 'Sem Status'
        },
        {
            title: 'Novo Status',
            dataIndex: 'newStatus',
            key: 'newStatus',
            render: (newStatus: string) => translateStatus(newStatus) || ''
        },
        {
            title: 'Responsável',
            dataIndex: 'auditEmail',
            key: 'auditEmail',
            render: (auditEmail: string) => auditEmail || ''
        },
        {
            title: 'Descrição da correção',
            dataIndex: 'observation',
            key: 'observation',
            render: (observation: string) => observation || ''
        },
    ];


    return (
        <>
            <div className='sales-registration-detail-header'>
                <Button type='link' onClick={() => history.goBack()} style={{ marginBottom: 16, color: '#FFF' }}>
                    <Icon type='left' style={{ fontSize: '18px' }} />
                </Button>
                <TitleSection title='Detalhes do Link' />
            </div>
            <div className='sales-registration-detail'>
                {
                    !link || loading
                        ? <Loading />
                        : (
                            <>
                                <div className='input-container'>
                                    <h1 className='detail-subheader'>Informações do Link</h1>
                                    <div className='duo-input-row'>
                                        <div className='link-id input-row'>
                                            <div className='input-link-id'>
                                                <label htmlFor='name'>ID do Link</label>
                                                <Input
                                                    id='name'
                                                    value={link.linkId}
                                                    disabled
                                                />
                                            </div>
                                            <Button className='open-checkout' onClick={() => dispatch(openCheckout(id))}>
                                                Abrir Checkout
                                            </Button>
                                        </div>
                                        <div className='input-row'>
                                            <label htmlFor='companyName'>Status</label>
                                            <Input
                                                id='status'
                                                placeholder='status'
                                                value={translateStatus(link.status)}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className='duo-input-row'>
                                        <div className='input-row'>
                                            <label htmlFor='fantasyName'>Nome do Coach</label>
                                            <Input
                                                id='nickname'
                                                placeholder='Nome do Coach'
                                                value={link.nickname || ''}
                                                disabled
                                            />
                                        </div>
                                        <div className='input-row'>
                                            <label htmlFor='fantasyName'>Coach Email</label>
                                            <Input
                                                id='coackEmail'
                                                placeholder='Coach Email'
                                                value={link.email || ''}
                                                disabled
                                            />
                                        </div>
                                    </div>

                                    <div className='duo-input-row'>
                                        <div className='input-row'>
                                            <label htmlFor='created'>Criação</label>
                                            <Input
                                                id='created'
                                                placeholder='Criação'
                                                value={formatDateWithHours(link.createdAt)}
                                                disabled
                                            />
                                        </div>
                                        <div className='input-row'>
                                            <label htmlFor='updateAt'>Atualização</label>
                                            <Input
                                                id='updateAt'
                                                placeholder='Atualização'
                                                value={formatDateWithHours(link.updatedAt)}
                                                disabled
                                            />
                                        </div>
                                    </div>

                                    <div className='duo-input-row'>
                                        <div className='input-row'>
                                            <label htmlFor='fantasyName'>Tipo do Produto</label>
                                            <Input
                                                id='productType'
                                                placeholder='Nome fantasia'
                                                value={link.productType || link.productId}
                                                disabled
                                            />
                                        </div>
                                        <div className='input-row'>
                                            <label htmlFor='fantasyName'>Nome Produto</label>
                                            <Input
                                                id='title'
                                                placeholder='Nome Produt'
                                                value={link.title || ''}
                                                disabled
                                            />
                                        </div>

                                        <div className='input-row'>
                                            <label htmlFor='companyName'>Preço</label>
                                            <Input
                                                id='price'
                                                placeholder='Preço'
                                                value={formatPrice(link.value / 100)}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='input-row observations-row'>
                                    <label htmlFor='notes'>Observações</label>
                                    <AntInput.TextArea
                                        id='notes'
                                        rows={4}
                                        placeholder='Observações'
                                        value={notes}
                                        onChange={e => setNotes(e.target.value)}
                                    />
                                </div>
                                <div className='action-buttons'>
                                    {renderActionButtons()}
                                    <Button type='primary' onClick={handleSaveObservation}>Salvar observações</Button>
                                </div>
                                <Table
                                    dataSource={decreAudits(link.audits)}
                                    rowKey={(e, index) => `${index}`}
                                    columns={columns}
                                    pagination={false}
                                    className='document-table'
                                />
                            </>
                        )
                }
            </div>
        </>
    );
};

export default React.memo(InternalLinksDetail);
