/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import { Form, Button } from 'antd';
import Search from 'antd/lib/input/Search';
import { useDispatch } from 'react-redux';
import './SearchFilter.scss';
import { asyncGetFilterPayment } from '../../../reducers/loose-payment.reducer';

interface IProps {
    isLoading: boolean,
    name: string,
    email: string,
    search: (payload: object) => void,
    setName: (name: string) => void,
    setEmail: (email: string) => void,
};

const LoosePaymentFilter = ({
    isLoading,
    name,
    email,
    search,
    setName,
    setEmail,
}: IProps) => {
    const dispatch = useDispatch();

    const searchByName = () => {
        dispatch(asyncGetFilterPayment({name, email}));
    };

    const searchByEmail = () => {
        dispatch(asyncGetFilterPayment({name, email}));
    };

    return (
        <Form className='filter-content2'>
            <div className='input-filter'>
                <label>Buscar por nome</label>
                <Search
                    value={name}
                    className='search-input'
                    placeholder='Nome'
                    onSearch={searchByName}
                    onChange={({ target }) => setName(target.value)} />
            </div>

            <div className='input-filter'>
                <label>Buscar por e-mail</label>
                <Search
                    value={email}
                    className='search-input'
                    placeholder='E-mail'
                    onSearch={searchByEmail}
                    onChange={({ target }) => setEmail(target.value)} />
            </div>

            <Button
                onClick={search}
                className='btn-search'
                type='primary'
                disabled={isLoading}
                icon='search'>
                Buscar
            </Button>
        </Form>
    )
};

export default LoosePaymentFilter;
