import React from 'react';
import { Pagination, Table } from 'antd';
import { formatDateUtc } from '../../../utils/moment.functions';
import { ILoosePayment } from '../../../models/loose-payment.model';

import './PaymentList.scss';
import Text from 'antd/lib/typography/Text';

interface IProps {
    payments: ILoosePayment[],
    isLoading: boolean,
    total: number,
    page: number,
    setCurrentPage: (page: number) => void,
};

const PaymentList = ({
    payments,
    isLoading,
    total,
    page,
    setCurrentPage,
}: IProps) => {

    const translateStatus = (status: string) => {
        if (status === 'CREATED') {
            return 'Criado'
        }
        if (status === 'PAID') {
            return 'Pago'
        }
        if (status === 'EXPIRED') {
            return 'Expirado'
        }
    };

    const columns = [
        {
            title: 'Nome Coach',
            dataIndex: 'nickname',
            key: 'nickname',
        },
        {
            title: 'E-mail',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Código',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Tipo',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Data Expiração',
            dataIndex: 'date_expiration',
            key: 'date_expiration',
            render: (text: any) => formatDateUtc(text)
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status: any) => <span className={`loose-status ${status}`}>{translateStatus(status)}</span>

        }
    ];

    return (
        <>
            <Table
                loading={isLoading}
                columns={columns}
                className={"table-payments"}
                rowClassName = {(payment: ILoosePayment) => payment.code ? 'row-canceled-payments' : 'row-payments' }
                dataSource={payments.map((payment: ILoosePayment) => ({ ...payment, key: payment.id }))}
                pagination={false}
            />
            <div style={{ textAlign: 'right', marginTop: 10, marginBottom: -15 }}>
                    <Text><b>{total}</b> pagamentos encontrados</Text>
            </div>
            <Pagination
                className="all-payments-pagination"
                showSizeChanger
                defaultCurrent={page}
                current={page}
                total={total}
                pageSizeOptions={['10', '25', '50', '100']}
                onChange={page => setCurrentPage(page)}
            />
        </>
    );
};

export default PaymentList;
